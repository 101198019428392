<template>
  <div class="assetConverge">
    <div class="assetConverge-content">
      <!-- 搜索 -->
      <div class="search">
        <v-input label="资产名称："></v-input>
        <div class="search-btn">
          <v-button
            text="搜索"
            style="margin-right: 13px"
            @click="handelSearch"
          >
          </v-button>
          <v-button text="添加" @click="handelClickAdd"> </v-button>
        </div>
      </div>
      <!-- 列表数据 -->
      <div class="list" id="followData" v-loading="isLoading">
        <template v-if="listData.length > 0">
          <div
            class="card"
            v-for="(item, index) in listData"
            :key="index"
            @click="handelSelect(item.id)"
          >
            <v-card :bodyStyle="bodyStyle">
              <div slot="header" class="header-title">
                <span>{{ item.name }}</span>
              </div>
              <div class="card-content">
                <div>更新周期：{{ item.cron }}</div>
                <div>
                  关联模型：{{
                    item.modelNames.length > 0
                      ? item.modelNames.join("、")
                      : "无"
                  }}
                </div>
              </div>
            </v-card>
          </div>
        </template>
        <template v-else>
          <div class="not-data">暂无数据</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { getAssetSourceData } from "./api.js";
export default {
  name: "assetConverge",
  components: {},
  props: {},
  data() {
    return {
      bodyStyle: {
        padding: "40px",
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
      pageTotal: "",
      isLoading: false,
    };
  },
  created() {},
  mounted() {
    this.getAssetSourceData();
    this.bindEventListener();
  },
  methods: {
    //handelSelect
    handelSelect(id) {
      this.$router.push({
        name: "assetConvergeForm",
        query: {
          id,
        },
      });
    },
    //搜索
    handelSearch() {
      this.requestData.curPage = 1;
      this.getAssetSourceData();
    },
    //添加
    handelClickAdd() {
      this.$router.push({
        name: "assetConvergeForm",
      });
    },
    bindEventListener() {
      this.$nextTick(() => {
        const el = document.getElementById("followData");
        if (!el) return;
        el.addEventListener("scroll", _.throttle(this.scrollHandler, 200));
      });
    },
    // 滚动分页加载
    scrollHandler() {
      const divHeight = document.getElementById("followData").offsetHeight;
      const nScrollHeight = document.getElementById("followData").scrollHeight;
      const nScrollTop = document.getElementById("followData").scrollTop;
      if (nScrollTop + divHeight + 1 >= nScrollHeight) {
        this.requestData.curPage = parseInt(this.requestData.curPage + 1);
        if (this.requestData.curPage > parseInt(this.pageTotal)) {
          return false;
        }
        this.getAssetSourceData();
      }
    },
    //请求列表数据
    getAssetSourceData() {
      this.isLoading = true;
      this.$axios
        .get(getAssetSourceData, { params: this.requestData })
        .then((res) => {
          if (res.code == 200) {
            this.isLoading = false;
            const { pages, records } = res.data;
            if (this.requestData.curPage == 1) {
              this.listData = records;
            } else {
              const ids = this.listData.map((item) => item.id);
              records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                ids.includes(item.id) || this.listData.push(item);
              });
            }
            this.pageTotal = pages;
            console.log("listData----->", this.listData);
          }
        });
    },
  },
  destroyed() {
    const el = document.getElementById("followData");
    el && el.removeEventListener("scroll", this.scrollHandler);
  },
};
</script>

<style scoped lang="less">
.assetConverge {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .assetConverge-content {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 50px 35px  40px 35px;
    box-sizing: border-box;
    background-color: #fff;
    .search {
      display: flex;
      justify-content: space-between;
      .search-btn {
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 30px;
      width: 100%;
      margin-top: 50px;
      padding-bottom: 20px;
      // background-color: pink;
      height: calc(100% - 90px);
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      .not-data {
        width: 100%;
        text-align: center;
      }
      .card {
        width: 370px;

        // text-align: center;
        .header-title {
          text-align: center;
        }
        .card-content {
          width: 100%;
          box-sizing: border-box;
          cursor: pointer;
          > div {
            font-size: 14px;
            &:first-child {
              margin-bottom: 18px;
            }
          }
        }
      }
    }
  }
}
</style>
